<template>
    <v-col>
        <v-overlay :value="overlay" >
            <v-progress-circular indeterminate size="64" />
        </v-overlay>
        <v-card>
            <v-row class="fill-height">
                <v-col class="text-center">
                    <div :style="externalServiceCrmId==null ? 'cursor:pointer' : 'pointer-events: none'">
                        <v-menu
                            bottom
                            right
                            style="width:90%;"
                            :allow-overflow="true"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    class="my-3"
                                    outlined
                                    color="grey darken-2"
                                    v-bind="attrs"
                                    v-on="on"
                                    style="width:90%"
                                >
                                    {{ typeof productStoreSelected != 'undefined' ? productStoreSelected.store_name : 'Tienda' }} 
                                    <v-icon right> mdi-menu-down </v-icon>  
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item-title  style="cursor:pointer">Tienda</v-list-item-title>
                                <v-list-item
                                    v-for="(ps, x) in productStores"
                                    :key="x"
                                    style="cursor:pointer"
                                    @click="setProductStoreIdEvento(ps)"
                                >
                                    <v-list-item-title style="cursor:pointer">{{ps.store_name}}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                    <v-date-picker 
                        v-model="fechaCalendario"
                        locale="es"
                    />
                    <div><b>Servicio seleccionado:</b></div>
                    <div 
                        class="mb-3 menu-servicio-calendario" 
                        :style="externalServiceCrmId==null ? 'cursor:pointer' : 'pointer-events: none'"
                    >
                        <v-menu
                            top
                            right
                            style="width:90%"
                            class="servicios"
                            attach=".menu-servicio-calendario"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    outlined
                                    color="grey darken-2"
                                    v-bind="attrs"
                                    v-on="on"
                                    style="width:90%;font-size: 13px;"
                                >
                                    <span></span>
                                    {{ typeof servicioSelected != 'undefined' ? (`${servicioSelected.nombre} Duración: ${ duracion }(min)`) : 'Todos los servicios' }}
                                    <v-icon right> mdi-menu-down </v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item-title @click="setServicioCrmIdEvento({servicio_crm_id: null})">
                                    Todos los servicios
                                </v-list-item-title>
                                <v-list-item 
                                    v-for="(serv, x) in servicios"
                                    :key="x"
                                    @click="setServicioCrmIdEvento(serv)"
                                >
                                    <v-list-item-title style="cursor:pointer">{{serv.nombre}} </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                    <div class="mb-3" style="width:90%;margin:0 auto">
                        <b>Sub servicios seleccionados:</b>
                        <div v-if="(typeof servicioSelected != 'undefined')">
                            <v-autocomplete
                                v-model="subServiciosSeleccionados"
                                :items="servicioSelected.sub_servicios"
                                dense
                                outlined
                                :single-line="true"
                                item-value="sub_servicio_crm_id"
                                :item-text="item => `${item.internal_name} ${item.duracion}min`"
                                multiple
                                hide-details
                                :disabled="externalServiceCrmId!=null"
                            />
                        </div>
                        <div v-else>Seleccione el servicio</div>
                    </div>
                    <div class="my-2">
                        <div><b>Horario seleccionado:</b></div>
                        <div>{{ horaOpcionAgendaCalendario }}</div>
                    </div>
                    <div 
                        class="my-2"
                        style="width: 90%;margin: 0 auto;"
                    >
                        <v-btn
                            class="white--text"
                            color="grey darken-1"
                            block
                            @click="getOpcionesAgendaCalendario"
                        >
                            <v-icon>mdi-calendar-clock</v-icon> VER DISPONIBILIDADES
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
        </v-card>
        <v-dialog
            width="750px"
            v-model=dialogOpcionesAgendaCalendario
            :persistent=true
            no-click-animation
            :retain-focus="false"
        >
            <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64" />
            </v-overlay>
            <v-toolbar 
                style="position: static;"
                class="blue-grey lighten-5"
            >
                <v-app-bar-nav-icon>
                    <template v-slot:default>
                        <v-icon color="primary">mdi-48px mdi-clock</v-icon>
                    </template>
                </v-app-bar-nav-icon>
                <v-card-title class="justify-center py-0">
                    HORARIOS DISPONIBLES (Tiempo necesario {{ duracion }}min)
                </v-card-title>
            </v-toolbar>
            <v-card>
                <p class="px-5 pt-3">
                    Agendar el <b>{{fechaCalendario}}</b> con: <b>{{ nombreOpcionAgendaCalendario }}</b> a las: <b> {{ horaOpcionAgendaCalendario }}</b> 
                    
                </p>
                <p class="px-5 pt-3">
                    <b>Cliente:</b> {{ this.clientName }} - <span><b>Oportunidad:</b> {{ this.oportunidadId!= null ? this.oportunidadId : ''}} </span>
                </p>
               
                <v-combobox
                    v-if="partyIdClient == null"
                    ref="combobox"
                    style="width: 350px;"
                    class="mt-1 ml-5 mb-4"
                    v-model="partyIdClient"
                    :search-input.sync="searchClienteList"
                    hide-details
                    :items="clientes"
                    label="Cliente"
                    item-text="nombre"
                    item-value="party_id"
                    outlined
                    dense
                    @keydown.enter="getClientes"
                    clearable
                />
        
                <v-divider></v-divider>
                <v-list two-line>
                    <v-list-item-group active-class="pink--text">
                        <template v-for="(opcion, x) in opcionesAgendaCalendario">
                            <v-list-item :key="x.party_id">
                                <template v-slot:default="{ active }">
                                    <v-list-item-content>
                                        <v-list-item-title> 
                                            <b>{{opcion.nombre}}</b>  
                                        </v-list-item-title>
                                        <v-list-item-subtitle :class="`text--primary`">
                                            Horarios: 
                                            <span :class="`${!opcion.disponibilidades[fechaCalendario].length ? 'red--text' :''}`">
                                                {{ opcion.horarios_creados }}
                                            </span> 
                                        </v-list-item-subtitle>                    
                                        <v-list-item-subtitle class="text--primary">Disponibilidades:</v-list-item-subtitle>                                   
                                            <v-row justify="space-around">
                                                <v-menu
                                                    v-for="(disp, y) in opcion.disponibilidades[fechaCalendario].filter(e => returnDiffMinutos(e) >= duracion)"
                                                    :key="y"
                                                    bottom
                                                    :close-on-content-click="false"
                                                    origin="center center"
                                                    transition="scale-transition"
                                                    class="round p-2"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn
                                                            small
                                                            color="primary"
                                                            class="my-3"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            @click="setPersonaYhora(opcion)"
                                                        >
                                                            <div>{{ disp.desde }} - {{ disp.hasta }}</div>
                                                        </v-btn>
                                                    </template>
                                                    <v-list>
                                                        <VueTimepicker  
                                                            hour-label="Hora" 
                                                            minute-label="Minuto"
                                                            drop-direction="bottom"
                                                            input-width="100%"
                                                            :second-interval="15"
                                                            v-model="horaOpcionAgendaCalendario"
                                                            auto-scroll
                                                            hide-disabled-items
                                                            close-on-complete
                                                            style="height: 190px;"                                                        
                                                            @change="rangoMinutos(disp)"
                                                            :hour-range="[[disp.desde.split(':')[0],disp.hasta.split(':')[0]]]" 
                                                            :minute-range="[rangoMinutos(disp)]"
                                                        />
                                                    </v-list>
                                                </v-menu>
                                            </v-row>
                                    </v-list-item-content>
                                </template>
                            </v-list-item>
                            <v-divider :key="x.party_id"></v-divider>
                        </template>
                    </v-list-item-group>
                </v-list>
                <v-card-actions>
					<v-col class='text-center'>
                        <v-btn 
                            @click="agendarCalendario({date: fechaCalendario, time: horaOpcionAgendaCalendario, hour: horaOpcionAgendaCalendario},'disponibilidades')"
                            class="text-center mr-2"
                            color="success"
                        >
                            <v-icon>mdi-floppy</v-icon> AGENDAR
                        </v-btn>
                        <v-btn 
                            @click="closeDialogOpcionesAgendaCalendario"
                            class="text-center"
                        >
                            <v-icon>mdi-cancel</v-icon> CERRAR
                        </v-btn>
					</v-col>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-col>
</template>

<script>

import { mapActions, mapState, mapMutations } from 'vuex'
import moment from 'moment'
import Vue from 'vue'
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'

export default {
    components:{
        VueTimepicker
    },
    name: 'AgendamientoComponent',
    props:{
        partyIdClient:{
            type: String,
            default: null
        },
        externalServiceCrmId:{
            type: Number,
            default: null
        },
        externalSubServiceCrmId:{
            type: Array,
            default: () => []
        },
        ordenServicioCitaId:{
            type: Number,
            default: null
        },
        oportunidadId:{
            type: String,
            default: null
        }
    },
    data: () => ({
        productStoreIdEvento:'',
        required: [v => !!v || 'El campo es requerido'],
        fechaCalendario: moment().format('YYYY-MM-DD'),
        dates: [
            moment().format('YYYY-MM-DD'),
            moment().format('YYYY-MM-DD')
        ],
        searchClienteList:'',
        clientes:[],
        productStores:[],
        servicios:[],
        subServiciosSeleccionados:[],
        opcionesAgendaCalendario:[],
        eqDispTotal:[],
        servicioCrmId:null,
        horaOpcionAgendaCalendario: '00:00',
        duracion:0,
        dialogOpcionesAgendaCalendario:false,
        nombreOpcionAgendaCalendario : '',
        partyIdCliente:null,
        overlay:false,
        horaOpcionAgendaCalendario: '00:00',
        clientName: null,
        partyIdEmploye:null
    }),
    watch:{

        subServiciosSeleccionados: function(){

            this.setSubServiciosSeleccionados()

        }

    },
    computed:{
        
        ...mapState('master',['loadingTable','tenantId','paramAlertQuestion','titleToolbar']),

        dateRangeText() {
            return this.dates.join(' ~ ')
        },

        productStoreSelected(){
          
            return this.productStores.find(e => e.product_store_id == this.productStoreIdEvento)

        },

        servicioSelected(){
           
           return this.servicios.find(e => e.servicio_crm_id == this.servicioCrmId)

       },

        descripcionAgendamiento(){
         
            return `${this.diaSeleccionado != null ? ('El '+moment(this.diaSeleccionado.date).format('LL')+' - a las ') : '' } ${ this.diaSeleccionado != null ? (this.diaSeleccionado.hour+' horas - con ') : '' } ${ this.clientName }`

        },

    },
    methods: {

        ...mapMutations('master',['setUrl','setLoadingTable','setTitleToolbar']),

        ...mapActions('master',['requestApi','alertNotification']),

        setProductStoreIdEvento(ps){
           
           this.productStoreIdEvento = ps.product_store_id
           this.getServicios()

        },

        getOpcionesAgendaCalendario(){

            if(typeof this.productStoreSelected == 'undefined' || !this.productStoreSelected.product_store_id){

                this.alertNotification({
                    param:{
                        html: 'Debe seleccionar la tienda',
                        timer: 10000,
                        title: 'Error',
                        icon: 'error',
                        confirmButtonColor: 'red'
                    }
                })

                return false

            }

            if(typeof this.servicioSelected == 'undefined' || !this.servicioSelected.servicio_crm_id){

                this.alertNotification({
                    param: {
                        html: 'Debe seleccionar el servicio',
                        timer: 10000,
                        title: 'Error',
                        icon: 'error',
                        confirmButtonColor: 'red'
                    }
                })

                return false

            }

            if(!this.subServiciosSeleccionados.length){

                this.alertNotification({
                    param:{
                        html: 'Debe seleccionar al menos un sub servicio',
                        timer: 10000,
                        title: 'Error',
                        icon: 'error',
                        confirmButtonColor: 'red'
                    }
                })

                return false

            }

            this.setUrl('evento')

            this.requestApi({
                method: 'GET',
                data: {
                    tenantId: this.tenantId,
                    servicioCrmId: this.servicioSelected.servicio_crm_id,
                    productStoreId: this.productStoreSelected.product_store_id,
                    fecha: this.fechaCalendario,
                    duracion:this.duracion,
                    typeList: 'horasPermitidas'
                }
            })
            .then(res =>{
                console.log('res',res)

                if(!res.data._embedded.evento[0].success){

                    this.alertNotification({
                        param: {
                            html: res.data._embedded.evento[0].msg,
                            timer: 10000,
                            title: "Error",
                            icon: "error",
                            confirmButtonColor: "red",
                        },
                    });

                    return false;

                }

                this.eqDispTotal = res.data._embedded.evento[0].eqDispTotal
                this.opcionesAgendaCalendario= res.data._embedded.evento[0].empleados


            })

            this.dialogOpcionesAgendaCalendario=true

        },

        getProductsStore(){

            this.setUrl('evento')

            this.overlay=true

            this.requestApi({
                method: 'GET',
                data: {
                    typeList: 'getProductStore',
                    partyId: this.partyIdClient
                }
            }).then(res =>{
                console.log('res.data._embedded.evento',res.data._embedded.evento)
                this.productStores = res.data._embedded.evento[0][0]
                this.productStoreIdEvento = res.data._embedded.evento[0][1].default_product_store_id

                if(this.partyIdClient != null && typeof res.data._embedded.evento[0][2] != 'undefined')
                    this.clientName = res.data._embedded.evento[0][2].first_name+' '+res.data._embedded.evento[0][2].last_name

                this.overlay=false

                this.getServicios()

            })

        },

        getServicios(){

            this.setUrl('evento')
            this.overlay= true

            this.requestApi({
                method: 'GET',
                data: {
                    typeList: 'getServicios',
                    productStoreId: this.productStoreIdEvento 
                }
            })
            .then(res =>{
               
                this.servicios = res.data._embedded.evento[0]
                this.overlay= false

                console.log('servicioSelected',this.servicioSelected,'externalSubServiceCrmId', this.externalSubServiceCrmId)
                if(this.externalSubServiceCrmId.length){

                    this.servicioSelected.sub_servicios = this.servicioSelected.sub_servicios.filter(e => this.externalSubServiceCrmId.includes(e.sub_servicio_crm_id))

                    this.subServiciosSeleccionados = this.externalSubServiceCrmId

                }

            })

        },

        setServicioCrmIdEvento({servicio_crm_id}){
           
           this.subServiciosSeleccionados=[]
           this.servicioCrmId = servicio_crm_id

        },

        setSubServiciosSeleccionados(){

            const {tiempo_antes , tiempo_despues} = this.servicioSelected

            this.duracion = this.servicioSelected.sub_servicios.filter(e => this.subServiciosSeleccionados.includes(e.sub_servicio_crm_id)).reduce((a,b) => a + parseFloat(b.duracion), 0)
            this.duracion+= parseFloat(tiempo_antes)+parseFloat(tiempo_despues)

        },

        closeDialogOpcionesAgendaCalendario(){

            this.dialogOpcionesAgendaCalendario = false
            this.opcionesAgendaCalendario = []
            this.horaOpcionAgendaCalendario = '00:00'
            this.nombreOpcionAgendaCalendario = ''

        },

        returnDiffMinutos({desde,hasta}){
        
            return moment(moment().format('YYYY-MM-DD '+hasta)).diff(moment().format('YYYY-MM-DD '+desde),'minutes')

        },

        rangoMinutos({ desde, hasta }, reagendar = false) {
      
            let hora = reagendar ? this.horaOpcionReagendadoCalendario : this.horaOpcionAgendaCalendario;
            
            console.log('desde',desde, 'hasta',hasta,  'hora',hora,'duracion',this.duracion)
            
            if (hora.split(":")[0] == desde.split(":")[0]) {
                
                //DESDE
                if (desde.split(":")[0] == hasta.split(":")[0]) {
                //DESDE Y HASTA TIENE EL MISMO INTERVALO DE HORA

                    //NO ALCANZAN LOS MINUTOS 
                    if(this.duracion > parseInt(hasta.split(":")[1])){

                        return [];

                    }else{

                        return [desde.split(":")[1], (hasta.split(":")[1]-this.duracion) < desde.split(":")[1] ? 59 : hasta.split(":")[1]-this.duracion];

                    }

                } else {

                    if(this.duracion > 59){

                        return [desde.split(":")[1], 59];

                    }else{

                        return [desde.split(":")[1], (59-this.duracion) < desde.split(":")[1] ? 59 : (59-this.duracion)];

                    }

                }

            } else {

                //TODOS LOS MINUTOS
                if(hora.split(":")[0] == hasta.split(":")[0] && hasta.split(":")[1] == "00"){
                    
                    return []

                }else{
                                    
                    if(hora.split(":")[0] > desde.split(":")[0] && hora.split(":")[0] <= hasta.split(":")[0]){
                       
                        return [0, 59]

                    }else if(hora.split(":")[0] == hasta.split(":")[0]){

                        //NO ALCANZAN LOS MINUTOS 
                        if(this.duracion > parseInt(hasta.split(":")[1])){

                            return []

                        }else{

                            if(this.duracion > 59){

                                return [hasta.split(":")[1], 59];

                            }else{

                                return [hasta.split(":")[1], (59-this.duracion) < hasta.split(":")[1] ? 59 : (59-this.duracion)];

                            }
                            
                        }

                    }

                }
                
            }

        },

        agendarCalendario(day,vista='disponibilidades'){
            
            // if(day.date >= moment().format('YYYY-MM-DD')){

                this.diaSeleccionado = day

                if(typeof this.productStoreSelected == 'undefined' || !this.productStoreSelected.product_store_id){

                    this.alertNotification({
                        param:{
                            html: 'Debe seleccionar la tienda',
                            timer: 10000,
                            title: 'Error',
                            icon: 'error',
                            confirmButtonColor: 'red'
                        }
                    })

                    return false

                }

                if(typeof this.servicioSelected == 'undefined' || !this.servicioSelected.servicio_crm_id){

                    this.alertNotification({
                        param:{
                            html: 'Debe seleccionar el servicio',
                            timer: 10000,
                            title: 'Error',
                            icon: 'error',
                            confirmButtonColor: 'red'
                        }
                    })

                    return false

                }

                if(this.partyIdCliente == null){

                    this.alertNotification({
                        param:{
                            html: 'Debe seleccionar el empleado',
                            timer: 10000,
                            title: 'Error',
                            icon: 'error',
                            confirmButtonColor: 'red'
                        }
                    })

                    return false

                }

                if(!this.subServiciosSeleccionados.length){

                    this.alertNotification({
                        param:{
                            html: 'Debe seleccionar al menos un sub servicio',
                            timer: 10000,
                            title: 'Error',
                            icon: 'error',
                            confirmButtonColor: 'red'
                        }
                    })

                    return false

                }

                if(this.partyIdCliente == null){

                    this.alertNotification({
                        param:{
                            html: 'Debe seleccionar el cliente',
                            timer: 10000,
                            title: 'Error',
                            icon: 'error',
                            confirmButtonColor: 'red'
                        }
                    })

                    return false

                }
            
                let hora = day.time.split(':')

                let originalHora = hora[0]
                let originalMin = hora[1]

                let minTime = Math.floor(originalMin/15) * 15

                if(minTime == 0)
                    minTime = minTime+'0'

                if(vista=='Calendario')
                    this.horaOpcionAgendaCalendario = originalHora+':'+minTime
                
                this.overlay= true
            
                this.setUrl('evento')

                this.requestApi({
                    method: 'GET',
                    data: {
                        tenantId: this.tenantId,
                        servicioCrmId: this.servicioSelected.servicio_crm_id,
                        productStoreId: this.productStoreSelected.product_store_id,
                        fecha: day.date,
                        partyId: this.partyIdEmploye,
                        duracion: this.duracion,
                        typeList: 'horasPermitidas'
                    }
                })
                .then(res =>{
                    
                    this.overlay= false
                
                    try {

                        let habilitado = res.data._embedded.evento[0].empleados[0].disponibilidades[this.fechaCalendario].find(e => {

                            return moment(this.fechaCalendario+' '+this.horaOpcionAgendaCalendario).isBetween(this.fechaCalendario+' '+e.desde,this.fechaCalendario+' '+e.hasta, undefined, '[]') && this.returnDiffMinutos({desde: this.horaOpcionAgendaCalendario,hasta : e.hasta})+1 >= this.duracion

                        })

                        if(typeof habilitado == 'undefined'){

                            this.alertNotification({
                                param:{
                                    html: `No es posible agendar a las ${this.horaOpcionAgendaCalendario}`,
                                    timer: 10000,
                                    title: 'Error',
                                    icon: 'error',
                                    confirmButtonColor: 'red'
                                }
                            })
                            
                            return false

                        }else{

                            Vue.swal({
                                html: `Esta seguro agendar el evento? <br /> Se agendará con las siguientes opciones:<br /> ${this.descripcionAgendamiento}`,
                                icon: "warning",
                                input: "textarea",
                                inputPlaceholder: 'Escriba un comentario',
                                showCancelButton: true,
                                confirmButtonText: "Aceptar",
                                cancelButtonText: "Cerrar",
                                ...this.paramAlertQuestion,
                            }).then((result) => {

                                if (result.isConfirmed) {

                                    this.setUrl('evento')
                                    this.overlay = true 

                                    this.requestApi({
                                        method: 'POST',
                                        data :{
                                            tenantId: this.tenantId,
                                            typeStore: 'guardarEventoCrm',
                                            servicioCrmId: this.servicioSelected.servicio_crm_id,
                                            productStoreId: this.productStoreSelected.product_store_id,
                                            clientePartyId: this.partyIdCliente.party_id,
                                            fecha: day.date+' '+this.horaOpcionAgendaCalendario,
                                            duracion: this.duracion,
                                            clientePartyId: this.partyIdCliente,
                                            employePartyId: this.partyIdEmploye,
                                            subServicios: this.subServiciosSeleccionados,
                                            comments: result.value,
                                            sales_opp_id: this.oportunidadId,
                                            ordenServicioCitaId: this.ordenServicioCitaId,
                                            equipoId: this.eqDispTotal.length ? this.eqDispTotal[0]: null
                                            //purposeId: '',
                                        }
                                    }).then(res =>{
                            
                                        try {

                                            this.alertNotification({
                                                param:{
                                                    html: res.data.res.msg
                                                }
                                            })  
    
                                            this.subServiciosSeleccionados =[]
                                            this.dialogOpcionesAgendaCalendario =false
                                            this.horaOpcionAgendaCalendario= '00:00'
                                            this.partyIdCliente = null
                                            this.overlay=false
                                            this.$emit('close')
                                            this.$emit('store')

                                        } catch (error) {
                                            console.log('error',error)
                                        }

                                    }).catch(()=>{
                                        
                                        this.overlay=false
                                        
                                    })  

                                }

                            })

                        }   
                        
                    } catch (error) {

                        console.log('error',error)

                    }

                })
                .catch(()=>{
                    
                    this.overlay=false
                    
                })  

            //}
    
        },

        setPersonaYhora({nombre, party_id}){
    
            this.horaOpcionAgendaCalendario = '00:00'
            this.nombreOpcionAgendaCalendario = nombre
            this.setEmpleadoIdEvento({party_id})

        },

        setEmpleadoIdEvento(emp){
           
           this.partyIdEmploye = emp.party_id

        },

    },
    created(){

        if(this.partyIdClient != null)
            this.partyIdCliente = this.partyIdClient

        if(this.externalServiceCrmId != null)
            this.servicioCrmId = this.externalServiceCrmId

        this.getProductsStore()

    }
}

</script>

<style scope>

.menu-servicio-calendario .menuable__content__active{

    height:550px;

}

</style>